const HTML_ENTITIES: Record<string, string> = {
  '&quot;': '"',
  '&amp;': '&',
  '&#39;': "'",
  '&lt;': '<',
  '&gt;': '>',
}

export const isNameInvalid = (name: string): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // Regular expression allows alphanumeric characters and -, &, <, >, ", ', #, $, %, /, =, :, @, |
      const re = /[^a-z0-9_ ()\-&<>"'#$%/=:@|]/gi
      resolve(name.length > 0 && re.test(name))
    }, 50)
  })
}

export const decodeName = (name: string) => {
  if (!name) {
    return
  }

  // Replace entities with their corresponding special characters
  return name.replace(
    /&[a-zA-Z0-9#]+;/g,
    (entity) => HTML_ENTITIES[entity] || entity
  )
}
